import TextInput from "../components/TextInput";


export default function Home() {
	return ( <>
		<div className="p-8">
			<div className="flex flex-col items-center w-full">
				<div className="w-72 max-sm:w-4/5 animate-fadeIn">

					<div className="flex flex-col items-center">
						<TextInput inputName="join-event-name" title="Enter an event code" placeholder="Event Code" button={{content: <span>Go</span>, title: "View event"}} />
					</div>

					
					<div className="flex items-center space-x-4 my-16">
						<hr className="w-full border-neutral-300 dark:border-neutral-700 transition-all" />
						<p className="tracking-widest">OR</p>
						<hr className="w-full border-neutral-300 dark:border-neutral-700 transition-all" />
					</div>

					<div className="w-full flex flex-col items-center">
						<label htmlFor="create-button" className="text-lg">Create an event</label>
						<a href="/create" id="create-button" title="Create an event" className="
							mt-4
							block mx-auto w-20 h-20
							border-2 rounded-full dark:border-neutral-700 bg-black bg-opacity-0
							group hover:scale-110 hover:bg-opacity-5 transition-all">
							<div className="relative w-full group-hover:scale-110 transition-all top-1/2">
								<span className="w-10 h-1 dark:bg-neutral-400 bg-neutral-800 block absolute left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full transition-all"></span>
								<span className="w-10 h-1 dark:bg-neutral-400 bg-neutral-800 block absolute left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full transition-all rotate-90"></span>
							</div>
						</a>
					</div>

				</div>
			</div>
		</div>
	</> );
}