import { Outlet } from "react-router-dom";
import Nav from "./Nav";
import Footer from "./Footer";
import { useEffect } from "react";

export const App = () => {
	useEffect(() => {
		const hash = document.location.hash.substring(1);
		const anchor = document.getElementById(hash + "-");
		anchor?.scrollIntoView({ behavior: "smooth", block: "start" });
	});

	return (
		<div className="w-full flex flex-col justify-between h-full items-stretch dark:text-white transition-all duration-500">
			<Nav />
			<div className="flex-auto border-y-2 bg-stone-100 dark:bg-neutral-900 dark:border-slate-700 transition-all shadow-inner">
				<Outlet />
			</div>
			<Footer />
		</div>
	);
}