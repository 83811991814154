import { useState } from "react";


export default function Toggle(props: { labels?: [string, string], callback?: ((value: boolean) => any) }) {
	const [value, setValue] = useState(false);

	return ( <>
		<button title={props.labels ? (value ? props.labels[0] : props.labels[1]) : value.toString()} className="
			w-full h-12
			bg-white
			dark:bg-neutral-800 dark:border-neutral-700
			dark:text-neutral-300
			transition-all
			border-2
			rounded-full
			flex justify-around items-center relative
			select-none
			cursor-pointer"
			onClick={ () => { setValue(!value); if(props.callback) props.callback(value); } }>
			
			{props.labels && <>
				<span className={value ? "font-normal" : "font-semibold"}>{props.labels[0]}</span>
				<span className={value ? "font-semibold" : "font-normal"}>{props.labels[1]}</span>
			</>}
			<div className={`
				w-1/2 h-4/5
				bg-black
				dark:bg-white
				bg-opacity-10 dark:bg-opacity-10
				absolute
				${value ? "left-1/2" : "left-2"} -ml-1
				rounded-full
				transition-all`}></div>
		</button>
	</> );
}