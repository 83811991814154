import { useNavigate } from "react-router-dom";
import Button from "./components/Button";
import { useEffect, useState } from "react";

export default function Nav() {
	const navigate = useNavigate();
	const [isDark, setDark] = useState(localStorage.getItem("colorScheme") === "dark");
	const [navOpen, setNavOpen] = useState(false);
	
	const navLinks: { title: string, content: string, onClick?: any }[] = [
		{ title: "Create event", content: "Create Event", onClick: () => navigate("/create") },
		{ title: "Toggle dark mode", content: isDark ? "☀️" : "🌙", onClick: () => { toggleColorScheme(); } }
	];

	const toggleColorScheme = () => {
		let colorScheme = localStorage.getItem("colorScheme");
		colorScheme = colorScheme === "light" ? "dark" : "light";
		setDark(colorScheme === "dark");
		setColorScheme(colorScheme as "dark" | "light");
	};
	
	const setColorScheme = (colorScheme: "dark" | "light") => {
		colorScheme = colorScheme === "dark" ? "dark" : "light";
		localStorage.setItem("colorScheme", colorScheme);
		colorScheme === "dark"?
			document.getElementsByTagName("html")[0].classList.add("dark")
			: document.getElementsByTagName("html")[0].classList.remove("dark");
		return colorScheme;
	}
	
	setColorScheme(localStorage.getItem("colorScheme") as "dark" | "light");

	return ( <>
		<div className={`
			flex-none h-16 w-full
			flex items-center justify-between max-sm:justify-center px-8
			bg-cyan-700 sm:from-cyan-700 sm:bg-gradient-to-r sm:to-cyan-900
			dark:from-transparent dark:to-transparent dark:bg-slate-800
			shadow-md shadow-gray-400 dark:shadow-neutral-900
			text-white
			z-10
			transition-all
		`}>

			<a href="/" className="text-3xl max-sm:text-center">Gather</a>

			<div className="max-sm:hidden flex-1 flex justify-end space-x-4">
				{navLinks.map(link =>
					<Button key={`${link.title}`} title={link.title} innerContent={<p>{link.content}</p>} onClick={link.onClick}></Button>
				)}
			</div>
		</div>
		<button className="
			w-full h-12 flex-none
			bg-cyan-700
			sm:hidden
			flex flex-col items-center justify-center
			transition-all
			cursor-pointer
			dark:bg-slate-800
			z-10"
		onClick={() => { setNavOpen(!navOpen); }}>
			<div className="space-y-1">
				{[...Array(3)].map((_, i) => 
					<span key={`menubutton-${i}`} className="w-8 h-1 bg-white dark:bg-neutral-300 block rounded-full transition-all"></span>
				)}
			</div>
		</button>
		<div className={`
			w-full mt-3
			flex-none
			absolute
			sm:hidden
			flex flex-col items-center justify-center
			transition-all
			border-b-2
			dark:border-neutral-700
			shadow-md
			z-0
			${navOpen? "translate-y-full" : "translate-y-0"}`}>
			{navLinks.map(link => 
				<button key={`mobile-${link.title}`} title={link.title} onClick={() => { link.onClick(); setNavOpen(false); }} className="
					w-full h-12
					text-center
					flex justify-center items-center
					select-none cursor-pointer
					bg-white
					dark:bg-slate-800
					transition-all
					focus:brightness-90
					focus:dark:brightness-110
					hover:brightness-90
					hover:dark:brightness-110">
						{link.content}
				</button>
			)}
		</div>
	</> );
}