

export default function Button(props: {title?: string, innerContent: JSX.Element, onClick?: () => void}) {
	return ( <>
		<button className="
			border-2
			hover:shadow-inner
			px-4 py-2
			rounded-xl
			transition-all
			border-white border-opacity-30"
		onClick={props.onClick}
		title={props.title}
		>{props.innerContent}</button>
	</> );
}