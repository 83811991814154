

export default function Footer() {
	return ( <>
		<div className="
			flex-none
			py-8
			flex justify-center items-center flex-col
			bg-neutral-900
			dark:bg-slate-800
			text-white text-center
			transition-all">

			<p className="text-sm">&copy;2023 Jack Clark</p>
		</div>
	</> );
}