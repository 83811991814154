

export default function TextInput(props: { inputName: string, title?: string, placeholder?: string, button?: { content?: JSX.Element, title?: string } } ) {

	return ( <>
			{props.title != null && <label htmlFor={props.inputName} className="text-lg">{props.title}</label>}
			<div className="w-full flex">
				<input id={props.inputName} type="text" placeholder={props.placeholder} className={`
					block my-1 px-2 py-2 w-full
					dark:bg-neutral-800 dark:border-neutral-700
					placeholder:text-neutral-500
					border-2 ${props.button?.content == null ? "rounded-full" : "rounded-l-full"}
					text-center transition-all`} 
					title={props.title}
				/>
				{props.button?.content != null &&
					<button className="
						my-1 pl-4 pr-5
						dark:bg-neutral-700 dark:border-neutral-700
						hover:dark:bg-neutral-600
						hover:bg-neutral-200
						border-2 border-l-0 rounded-r-full
						font-semibold
						transition-all
					"
					title={props.button.title}>{props.button.content}</button>
				}
			</div>
	</> );
}