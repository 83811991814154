import { useEffect, useState } from "react";
import TextInput from "../components/TextInput";
import Toggle from "../components/Toggle";
import { TupleType } from "typescript";

const getWeekdays = (weekOffset: number = 0) => {
const now = new Date(Date.now());
return [...Array(7)].map((_, i) => {
	const today = new Date(now.getTime() + (7 * weekOffset - now.getDay() + i) * (24 * 60 * 60 * 1000));
	return today;
});
}

const CalendarComponent = () => {

const maxRowOffset = 52
const [rowOffset, setRowOffset] = useState(0);
const [highestRowOffset, setHighestRowOffset] = useState(0);
type DateCoord = { week: number, dow: number }
const initialSelectedDates: DateCoord[] = [];
// const [pressedDate, setPressedDate] = useState([0, 0]);
// const [releasedDate, setReleasedDate] = useState([0, 0]);
const [selectedDates, setSelectedDates] = useState(initialSelectedDates);

const dateCoordToIndex = (week: number, dow: number) => week * 7 + dow;

const isDateSelected = (week: number, dow: number) => selectedDates.some(d => d.week === week && d.dow === dow);

const selectDate = (week: number, dow: number) => {
	if(!isDateSelected(week, dow)) {
		setSelectedDates(selectedDates.concat({ week: week, dow: dow }));
	}
}

const deselectDate = (week: number, dow: number) => {
	if(isDateSelected(week, dow)) {
		setSelectedDates(selectedDates.filter(d => !(d.week === week && d.dow === dow)));
	}
}

const toggleDate = (week: number, dow: number) => {
	if(!isDateSelected(week, dow))
		selectDate(week, dow);
	else
		deselectDate(week, dow);
}

return ( <>
	<p className="animate-fadeIn">Selected {selectedDates.length} dates</p>
	<h3 className="text-center animate-fadeIn">{getWeekdays(2 + rowOffset)[3].toLocaleDateString('en-us', { year: "numeric", month: "long" })}</h3>
	<div className="
		rounded-2xl
		overflow-hidden
		w-[36rem] max-sm:w-11/12
		border-2
		bg-gray-100
		dark:bg-neutral-800 dark:border-neutral-700
		dark:text-neutral-300
		transition-colors
		animate-fadeIn">
		<div className="
			w-full h-12
			flex justify-around items-center
			border-b-2
			bg-cyan-700 dark:bg-slate-800
			text-white
			transition-all
			font-bold
			dark:border-neutral-700">
			<span className="w-1/2 text-center"></span>
			{[..."SMTWTFS"].map((dow, i) =>
				<span key={`calendar-header-${i}`} className="
					w-full h-full
					flex justify-center items-center
				">{dow}</span>
			)}
		</div>
		<div className="overflow-y-scroll overflow-x-hidden h-60 snap-y snap-mandatory"
			onScroll={e => {
				setRowOffset(Math.min(maxRowOffset - 4, Math.floor((e.currentTarget.scrollTop + 24) / 48)));
				setHighestRowOffset(Math.max(highestRowOffset, rowOffset));
			}}>
			{[...Array(Math.min(highestRowOffset + 8, maxRowOffset + 1))].map((_, i) =>
				<div key={`week-${i}`} id={`week-${i}`} className="w-full h-12 flex justify-around items-center snap-start">
					<button
						className="w-1/2 h-full border-r-2 border-gray-200 dark:border-neutral-600 transition-all"
						onClick={e => {
							const dows = [...Array(7)].map((_, i) => i);
							let newSelectedDates = [...selectedDates];
							
							const allOn = !dows.some(dow => isDateSelected(i, dow));
							dows.forEach(dow => {
								const beforeToday = getWeekdays(i)[dow].getTime() < Date.now() - 1;
								if(beforeToday) return;
								if(allOn) {
									if(!isDateSelected(i, dow)) {
										newSelectedDates = newSelectedDates.concat({ week: i, dow: dow });
									}
								} else {
									if(isDateSelected(i, dow)) {
										newSelectedDates = newSelectedDates.filter(d => !(d.week === i && d.dow === dow));
									}
								}
							});
							setSelectedDates(newSelectedDates);
						}}
					>&#x25B6;&#xFE0E;</button>
					{getWeekdays(i).map((date, dow) => 
						<button key={`week-${i}-day-${dow}`} className={`
							w-full h-full
							flex items-center justify-center
							group
							disabled:opacity-25
							disabled:cursor-not-allowed
							transition-colors
							relative
							after:w-3/4 after:h-5/6 after:rounded-xl after:absolute after:bg-black after:dark:bg-white after:opacity-0
							after:hover:sm:opacity-25 after:transition-all
							${dow !== 0 && getWeekdays(i)[dow - 1].getMonth() !== date.getMonth() ? "rounded-tl-xl" : ""}
							${dow !== 6 && getWeekdays(i)[dow + 1].getMonth() !== date.getMonth() ? "rounded-br-xl" : ""}
							${date.getMonth() % 2 === 0 ? "bg-gray-300 dark:bg-neutral-700" : ""}
							`}
							disabled={date.getTime() < Date.now() - 1}
							// onMouseUp={e => { setEventThing(e.type); e.preventDefault(); e.stopPropagation(); }}
							// onTouchStart={e =>{}}
							// onTouchEnd={e => { e.preventDefault(); e.stopPropagation(); }}
							// onClick={e => { if(e.altKey) { setReleasedDate([i, dow]) } else { setPressedDate([i, dow]) } }}
							// onMouseDown={e => { setPressedDate([i, dow]); }}
							// onMouseUp={e => { setReleasedDate([i, dow]); }}
							onClick={e => {
								toggleDate(i, dow);
							}}
							>
							<span className={`
								w-10 h-10 z-10 flex items-center justify-center
								rounded-full
								${isDateSelected(i, dow)
									? "bg-cyan-700 dark:bg-blue-400 dark:bg-opacity-20 bg-opacity-50"
									: "bg-black dark:bg-white bg-opacity-0 dark:bg-opacity-0"}
								group-disabled:bg-opacity-0
								duration-100
								transition-[background-color]
							`}>{date.getDate()}</span>
						</button>
					)}
				</div>
			)}
		</div>
	</div>
	
	{/* <pre>{selectedDates.map(d => `[${d.week} ${d.dow}]`).join(", ")}</pre> */}

	<div className="
		w-[36rem] max-sm:w-4/5 h-12 mt-4
		border-2
		dark:border-neutral-700
		rounded-full
		flex justify-around
		transition-all
		animate-fadeIn
	">
		<button className="
			w-full h-full flex justify-center items-center
			rounded-full
			bg-black dark:bg-white
			bg-opacity-0 dark:bg-opacity-0
			enabled:hover:bg-opacity-10 enabled:dark:hover:bg-opacity-10
			disabled:opacity-20
			transition-all
			"
		disabled={rowOffset === 0}
		onClick={ e => { document.querySelector(`#week-${rowOffset - 1}`)?.scrollIntoView({ behavior: "smooth" }) }}
		>
			<span className="w-4 h-4 border-l-4 border-t-4 block rotate-45 translate-y-1 rounded-sm border-black dark:border-gray-200 transition-all"></span>
		</button>
		<button className="
			w-full h-full flex justify-center items-center
			rounded-full
			bg-black dark:bg-white
			bg-opacity-0 dark:bg-opacity-0
			enabled:hover:bg-opacity-10 enabled:dark:hover:bg-opacity-10
			disabled:opacity-20
			transition-all
			"
		disabled={rowOffset === maxRowOffset - 4}
		onClick={ e => { document.querySelector(`#week-${rowOffset + 1}`)?.scrollIntoView({ behavior: "smooth" }) }}>
			<span className="w-4 h-4 border-l-4 border-t-4 block -rotate-[135deg] -translate-y-1 rounded-sm border-black dark:border-gray-200 transition-all"></span>
		</button>
	</div>
</> );
}

export default function Create() {
const [dateRange, setDateRange] = useState(true);

return ( <>
	<div className="p-8">
		<div className="flex flex-col items-center w-full animate-fadeIn">
			<div className="w-72 max-sm:w-4/5 text-center">
				<TextInput inputName="create-event-name" title="Create an event" placeholder="Event name" />
				<div className="h-8"></div>
				<Toggle labels={["Date Range", "Weekday"]} callback={(value: boolean) => { setDateRange(value) }} />
			</div>

			{dateRange
			? <div className="w-full flex flex-col items-center">
				<h2 className="mt-8 mb-2 text-lg text-center animate-fadeIn">Pick a date range</h2>

				<CalendarComponent />
			</div>
			: <div className="animate-fadeIn">
				<h2 className="mt-8 mb-2 text-lg text-center">Choose a set of weekdays</h2>

				<div className="w-full h-24 border-2 rounded-2xl">
					<div className="w-full h-full flex py-2">
						{
							["S", "M", "T", "W", "T", "F", "S"].map((day, i) =>
								<span key={`day-${i}`} className={`w-full text-center ${i % 3 <= 1 ? "bg-green-200" : ""}`}>{day}</span>)
						}
					</div>
				</div>
			</div>}

		</div>
	</div>
</> );
}